import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import React, { Fragment, Key, SyntheticEvent, useRef } from 'react'
import Icon from 'src/components/icons/icon'

import { FormInputProps } from '../fields/types'

const logger = debug('app:multi-listbox-input')

// UPDATE NOTE: most of our older usages store the "value" in react-hook-form as an array with a single ListOption object
//  - This has led to a lot of verbose code to wrap/unwrap values to and from ListOption objects.
//  - Values can now be tracked as JUST the string/null value, and the component will handle wrapping/unwrapping internally as needed.
//  - If passing in value/onChangeHandler and using without react-hook-form, you'll continue to recieve the full ListOption object and need
//    to do _unwrapping_ yourself, but pretty simple in that case.

// Generic so you can type the value if it's something other than a string
export interface ListOption<ValueType = string | null> {
  value: ValueType
  label: string | JSX.Element
  description?: string
  disabled?: boolean
  divider?: boolean
}

export type MultiListboxInputProps = Omit<FormInputProps, 'value'> & {
  options: ListOption[]
  portal?: boolean
  compact?: boolean
  optionsClassName?: string
  onChange?: (value: string[]) => void
  hasError?: boolean
  value?: string[]
}

const MultiListboxInput = ({
  name,
  label,
  useNestedLabel,
  placeholder,
  options,
  value,
  hasError,
  disabled,
  className,
  optionsClassName,
  style,
  onChange,
  compact = false,
  portal = true,
}: MultiListboxInputProps) => {
  if (useNestedLabel && !label) {
    throw new Error('useNestedLabel requires a label')
  }
  // Track the width of the button to set the width of the options list (when using portal)
  const buttonRef = useRef<HTMLDivElement>(null)
  const selectedItems = value?.map((v: string) => options.find((o) => o.value === v) as ListOption)

  const handleChange = (selectedOptions?: ListOption[]) => {
    const values = selectedOptions?.map((o) => o.value) as string[]
    if (onChange) onChange(values)
  }

  const removeItem = (itemToRemove: ListOption) => (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    handleChange(selectedItems?.filter((item: ListOption) => item.value !== itemToRemove.value))
  }

  return (
    <Listbox value={selectedItems} by='value' onChange={handleChange} disabled={disabled} multiple>
      {({ open }) => (
        <div className={clsx('relative', compact && 'mt-1', className)} style={style} ref={buttonRef}>
          <ListboxButton
            id={name}
            aria-describedby={`${name}-description`}
            className={clsx(
              'relative w-full cursor-default rounded-md border bg-white pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm',
              hasError && 'border-error-8 focus:border-error-5 focus:ring-error-5',
              disabled && 'cursor-not-allowed border-gray-400 text-gray-400',
              !hasError && !disabled && 'border-black focus:border-gray-500 focus:ring-gray-500',
              !compact && 'min-h-[44px] py-2',
              compact && 'h-[34px] max-h-[34px] pb-2 pt-1.5',
            )}
          >
            <div className='truncated flex flex-wrap gap-1'>
              {!selectedItems?.length && (placeholder || useNestedLabel) ? (
                <span className='text-gray-500'>{useNestedLabel ? label : placeholder}</span>
              ) : null}
              <div>
                {useNestedLabel && selectedItems?.length && selectedItems?.length > 0 ? (
                  <span className={'-mb-0.5 -mt-1 block bg-transparent text-xs text-gray-500'}>{label}</span>
                ) : null}
                <div className='mt-2 flex gap-1'>
                  {selectedItems?.map((currentSelected) => (
                    <div
                      tabIndex={-1}
                      onClick={removeItem(currentSelected)}
                      aria-label={`Remove selected option ${currentSelected.label} from ${label}`}
                      key={currentSelected.value as Key}
                    >
                      <div className='bg-information-0 hover:bg-information-1 text-information-9 border-information-3 flex items-baseline rounded-md border px-2 py-1 text-xs transition-all'>
                        <span className='flex items-center justify-center gap-2 whitespace-nowrap'>
                          {currentSelected.label}
                          <Icon name='close' size={10} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <Icon name='chevron-down' size={18} style={{ color: disabled ? 'rgb(156, 163, 175)' : '#000' }} />
            </span>
          </ListboxButton>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <ListboxOptions
              anchor='bottom start'
              className={clsx(
                'border-neutral-4 absolute z-10 max-h-60 overflow-auto rounded-md border bg-white text-base shadow-2xl focus:outline-none sm:text-sm',
                optionsClassName,
              )}
              style={{ width: buttonRef.current?.offsetWidth }}
              portal={portal}
            >
              {options.map((option, i) => {
                if (option.divider) {
                  return <hr className='my-2 w-full' key={i} />
                }
                const isSelected = selectedItems?.some((selectedOption) => selectedOption.value === option.value)
                return (
                  <ListboxOption
                    key={option.value}
                    className={({ focus }) =>
                      clsx(
                        'relative w-full cursor-default select-none py-2 pl-2 pr-9',
                        focus && 'bg-information-0',
                        option.disabled ? 'text-gray-400' : 'text-gray-900',
                      )
                    }
                    value={option}
                    disabled={option.disabled}
                  >
                    <>
                      <div className='flex flex-col gap-1'>
                        <span className='block truncate text-sm'>{option.label}</span>
                        <span className='text-neutral-8 block text-xs'>{option.description}</span>
                      </div>
                      {isSelected ? (
                        <span className='text-information-9 absolute inset-y-0 right-0 flex items-center pr-4'>
                          <Icon name='success' size={18} aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  </ListboxOption>
                )
              })}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default MultiListboxInput
