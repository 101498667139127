import clsx from 'clsx'
import NavBar from 'src/components/navigation/nav-bar'

export type DefaultLayoutProps = {
  children: React.ReactNode
  showNavBar?: boolean
  className?: string
  [key: string]: any
}

const DefaultLayout = ({ children, showNavBar = true, className, ...rest }: DefaultLayoutProps) => (
  <div className={clsx(className, 'flex flex-grow flex-col')} {...rest}>
    {showNavBar && <NavBar />}
    {children}
  </div>
)

export default DefaultLayout
